import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { addNote, setSearch } from '../actions/noteActions'
import { withRouter } from 'react-router-dom';

import { Tabs, List, Card } from 'antd';

import NoteComponent from '../functional/NoteComponent'


const { TabPane } = Tabs;



//FIXME: REVIEW!
const mapStateToProps = (state) => {
  return {
        notes: state.notes
      }
}


const mapDispatchToProps= (dispatch)=>{

    return{
        addNote: (note) => { dispatch(addNote(note)) }, //FIXME: DO BETTER, RENAME, REM
        setSearch: (search) => { dispatch(setSearch(search)) } //FIXME: DO BETTER, RENAME
    }
}

//FIXME: MAKE TABS STICKY https://ant.design/components/tabs/
//https://stackoverflow.com/questions/59114027/how-to-remove-duplicates-from-array-in-react-native
class ListPanel extends Component {

  //FIXME: ADD TAG CLICK/SEARCH?

  noteClick = (note) => {
    //this.props.addNote(note); //FIXME: CHANGE
    this.props.history.push(`/page/${note.uuid}`);
  }

  getNotesList = () => {
    let notesList = this.props.notes.map(note=>{

      return (
        <NoteComponent note={note} onTagClick={(e, tag) => {

        }}
        noteClicked={(note) => {
          this.noteClick(note);
        }} />
      )
    })
    return notesList;
  }

  getTagsList = () => {
    /*
    let allTags = this.props.notes.map(note=>{

    })
    */
   let allTags = [];

   for(let note of this.props.notes)
   {
     allTags = [
       ...allTags,
       ...note.tags
     ]
   }
   // console.log('at', allTags);
   //FIXME: DO BETTER TAG SEARCH EVENTUALLY (EG tag:<TAG>)
    return (
      <Card style={{width:'85%', display:'block', 'margin-left':'auto', 'margin-right':'auto'}}>
        <List style={{'flex':'1 0 0', 'overflow-y':'auto'}} dataSource={allTags} renderItem={item => (
        <List.Item key={item}>
          <Card hoverable size="small" bordered={false} style={{width:'100%', padding:'0px', margin:'0px'}} onClick={()=>{
            this.props.setSearch(item);
            this.props.history.push(`/`); //Set to search page
          }}>
          {item}
          </Card>
        </List.Item>
      )}>
      </List>
      </Card>
    )
  }

  render() {
    return (
    <Tabs className="tabList" style={{'flex':'1 1 auto', 'margin-right':'-16px', 'margin-left':'-16px'}} defaultActiveKey="1" centered>
      <TabPane style={{ 'flex':'1 1 auto', 'overflow':'auto'}} tab="Notes" key="1">
        {this.getNotesList()}
      </TabPane>
      <TabPane  style={{ 'flex':'1 1 auto', 'overflow':'auto'}} tab="Tags" key="2">
        {this.getTagsList()}
      </TabPane>
    </Tabs>
  )
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListPanel)); //FIXME: REVIEW!
