import React from 'react';
import uuid from 'react-uuid'
import { Component } from 'react';
import { connect } from 'react-redux';
import { addNote, putNote, delNote } from '../actions/noteActions'
import { withRouter } from 'react-router-dom';

import { Card, Row, Col, Form, Input, Button, List, Modal, Tooltip } from 'antd';

import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import Tagger from '../temp_taginput'

import InfoTooltip from '../functional/InfoTooltip'

import {noteSuggestions} from '../../util/SearchUtils';

const { TextArea } = Input;


//FIXME: REVIEW!
const mapStateToProps = (state) => {
  return {
        notes: state.notes
      }
}


const mapDispatchToProps= (dispatch)=>{

    return{
        addNote: (note) => { dispatch(addNote(note)) }, //FIXME: DO BETTER, RENAME
        putNote: (note) => { dispatch(putNote(note)) },
        delNote: (uuid) => { dispatch(delNote(uuid)) },
    }
}

class AddPanel extends Component {

//FIXME: REMOVE
  state = {selectedNote: null}

  constructor(props) {
     super(props);
     this.updatePage(); //Get the page id if there is one
  }

  //Used to set the page to correspond to the URL. Modified from PagePanel
  updatePage = () => {

    this.pageid = this.props.match.params.pageid;

    if(this.currentNote != null && this.pageid == null) //We just switched
    {
      this.currentNote = null;
      this.formRef.current = null;
    }
    if(this.pageid == null)
    {
      //this.currentNote = null; //CLear the page (in part for our list of similar)
      //this.formRef.current = null; //Clear the form data?
      return;
    }

    for(let pg of this.props.notes)
     if(this.pageid === pg.uuid) //FIXME: USE THIS SORT OF THING MORE
     {
         this.currentNote = pg;
         break;
     }
     console.log(this.currentNote);
  }

/*
  showWarning = ()  => {
    this.setState({
      warningvisible : true,
    });
  }

  closeWarning = () => {
    this.setState({
      warningvisible : false,
    });
  }
  */

  formRef = React.createRef();
  mergeRef = React.createRef();

  //FIXME: REVIEW
  handleClick = (note) => {
    this.props.addNote(note); //FIXME: CHANGE
  }

  resetWarning = () => {
    Modal.confirm({
      title: 'Confirm reset',
      content: 'If you reset now, the current note will not be saved.',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {this.resetForm();},
      onCancel: () => {}
    });
  }

  goBackWarning = () => {

    let possibleNotes = this.props.notes.filter(note => {
      return note.uuid === this.state.selectedNote.uuid; //FIXME: DO BETTER, STORE ORIG?
    })

    console.log('pn', possibleNotes);
    console.log('fd', this.mergeRef.current.getFieldsValue());
    console.log(possibleNotes[0]);


    let noteObj = this.mergeRef.current.getFieldsValue();

    // console.log(noteObj);

    // console.log(possibleNotes[0] === noteObj);
    let sameTitle = possibleNotes[0].title === noteObj.title;
    let sameTags = possibleNotes[0].tags === noteObj.tags;
    let sameBody = possibleNotes[0].body === noteObj.contents; //FIXME: RENAME TO BODY

    console.log(possibleNotes.length !== 1, !sameTitle, !sameTags, !sameBody);

    if(possibleNotes.length === 1 && sameTitle && sameTags && sameBody)
    {
      this.undoMerge();
    }
    else
      Modal.confirm({
        title: 'Confirm revert',
        content: 'If you go back now, any changes to the existing note will be deleted.',
        icon: <ExclamationCircleOutlined />,
        onOk: () => {this.undoMerge(); },
        onCancel: () => {}
      });
  }

  //FIXME: CHANGE LINK TO BE THIS PAGE? https://www.markdownguide.org/basic-syntax/
  onSubmit = values => {
    let tags = []; //FIXME: EDIT TAGS TO MAKE THIS NOT HAPPEN?

    for(let temp of values.tags) //FIXME: REMOVE
    {
      // console.log('temp',temp);
      tags.push(temp);
    }

    let newNote = {
      title: values.title,
      tags: tags,
      body: values.contents,
      uuid: ((this.currentNote == null) ? uuid() : this.currentNote.uuid)
    }

    if(this.currentNote == null)
      this.props.addNote(newNote);
    else
      this.props.putNote(newNote);

    this.props.history.push(`/page/${newNote.uuid}`);
  };

  onMerge = values => {
    // console.log("hey?", values);
    let tags = []; //FIXME: EDIT TAGS TO MAKE THIS NOT HAPPEN?

    for(let temp of values.tags) //FIXME: REMOVE
    {
      // console.log('temp',temp);
      tags.push(temp);
    }

    let newNote = {
      title: values.title,
      tags: tags,
      body: values.contents,
      uuid: this.state.selectedNote.uuid
    }

    this.props.putNote(newNote);

    if(this.currentNote != null) //we were editing a note
      this.props.delNote(this.currentNote.uuid);

    this.props.history.push(`/page/${newNote.uuid}`);
  };

  //FIXME: SHOW WARNING. https://ant.design/components/modal/
  resetForm = () => {
    this.formRef.current.resetFields();
  }
  undoMerge = () => {
    this.setState({selectedNote: null});
  }

  //FIXME: RENAME, DO BETTER
  getTooltipTitle = () => {
    return (
    <div>
    Similar Notes <InfoTooltip text="Similar notes are listed below. Click on one of them to compare and merge it with your current note."/>
    </div>
    )
  }

  getContentsTitle = () =>{
    return (
      <div>
      Contents <InfoTooltip text="Markdown can be used to style your notes. Click here for reference."  onClick={() => {window.open("https://commonmark.org/help/")}}/>
      </div>
    )
  }
  //FIXME: RENAME, DO BETTER
  getOtherTitle = () => {
      if(this.state.selectedNote == null)
        return this.getTooltipTitle();
      else
        return (
             "Existing Note"
        )

  }
  //FIXME: DO BETTER, RENAME
  //FIXME: FIX HEADERS (EG SIMILAR NOTES) AND ALLOW REST TO SCROLL
  //FIXME: ADD TOOLTIP WHEN DISABLED
  getOtherView = () => {

    //FIXME: THIS DOESNT UPDATE
    //Maybe use these? <PageHeader title="Existing Note" onBack={() => {}} />
    if(this.state.selectedNote == null)
    {
        //FIXME: REFACTOR
        let tags = (this.formRef.current == null) ? ((this.currentNote == null) ? null : this.currentNote.tags) : this.formRef.current.getFieldsValue().tags;
        let title = (this.formRef.current == null) ? ((this.currentNote == null) ? null : this.currentNote.title)  : this.formRef.current.getFieldsValue().title;

        //Key to make it re-render when we switch from edit to add FIXME: DO BETTER
        return (
          <>
          <List key={this.currentNote} style={{'flex':'1 0 0', 'overflow-y':'auto'}} dataSource={noteSuggestions(this.props.notes, title, tags, (this.currentNote != null) ? this.currentNote.uuid : null)} renderItem={item => (
            <List.Item key={item.uuid}>
              <Card hoverable size="small" bordered={false} style={{width:'100%', padding:'0px', margin:'0px'}} onClick={() =>{
                this.setState({selectedNote:item});
                //console.log('sn', item);
              }}>
              {item.title}
              </Card>
            </List.Item>
          )}>
          </List>
          </>
        )
    }
    else
    {

      //if(this.formRef.current != null)
      //  console.log('fr', this.formRef.current.getFieldsValue());
      return (
        <>
        <Form layout="vertical" name="add-form" initialValues={{title:this.state.selectedNote.title, tags:this.state.selectedNote.tags, contents:this.state.selectedNote.body}} ref={this.mergeRef} onFinish={this.onMerge}>
          <Form.Item label="Title" name="title" rules={[{
              required: true,
              message: 'Please enter a title.',
              },]}>
              <Input />
          </Form.Item>


          <Tagger tags={this.state.selectedNote.tags} />

          <Form.Item label={this.getContentsTitle()} className="contentForm" style={{'flex':'0 1 auto', 'height':'100%'}}  name="contents" rules={[{
              required: true,
              message: 'Please enter the contents of your note.',
              },]}>
            <TextArea  className="addNoteContent" placeholder="" />
          </Form.Item>

           <Form.Item style={{'margin-bottom':'0px'}}>
           <Button type="primary" htmlType="submit">
            Merge
            </Button>

            <Button onClick={this.goBackWarning} danger>
              Go Back
            </Button>
          </Form.Item>

        </Form>
        </>
      )
    }
  }

  getDeleteButton = () => {
    return (
      <Tooltip title="delete">
        <Button shape="circle" type="link" icon={<DeleteOutlined />} />
      </Tooltip>
    )
  }


  render() {
  //  let otherView = this.getOtherView();
//FIXME: MAKE BOTH COLS SAME HEIGHT
//FIXME: LAYOUT, MAKE THINGS APART SEE SAMPLE
//FIXME: MAKE WARNINGS SHOW ON TAB SWITCH>
//FIXME: HIDE TAGGER
  //FIXME: DO A CHECK HERE, OR IS THERE NO REASON TO? IS THE ONLY REASON FOR AN UPDATE A PAGE REFRESH?
    this.updatePage(); //Make sure we match the page

    let titleTxt = (this.state.selectedNote == null) ? ((this.currentNote == null) ? "Create a New Entry" : "Edit Note") : "Merge and Compare";
    let currText = (this.currentNote == null) ? "Create a New Note" : "Current Note"; //FIXME: MAKE SURE DELETE NOTES?

    let initVals = (this.currentNote == null) ? null : {title:this.currentNote.title, tags:this.currentNote.tags, contents:this.currentNote.body};
    //initialValues={{title:this.state.selectedNote.title, tags:this.state.selectedNote.tags, contents:this.state.selectedNote.body}}
    // console.log('iv', this.currentNote);
    let extra = (this.currentNote == null) ? null : this.getDeleteButton();

    //Set row key to try to force re-construction when we switch from edit to add
    return (
      <>
        <h1 style={{'text-align':'center', 'padding-bottom':'8px'}}> {titleTxt} </h1>

        <Row key={this.currentNote} gutter={[16, 16]} style={{'flex':'1 1 auto'}}>
            <Col span={12} style={{'display':'flex'}}>
              <Card title={currText}  extra={extra} className="compare-cards" bodyStyle={{'flex': '1 0 0', 'overflow-y':'auto', height:'100%', display:'flex', 'flex-direction':'column'}}>
                <Form layout="vertical" initialValues={initVals} name="add-form" ref={this.formRef} onFinish={this.onSubmit} onValuesChange={()=>{
                  this.setState({}); //Used to update suggestions
                }}>
                  <Form.Item label="Title" name="title" rules={[{
                      required: true,
                      message: 'Please enter a title.',
                      },]}>
                      <Input readOnly={this.state.selectedNote != null} placeholder="Note Title"/>
                  </Form.Item>

                  {this.currentNote == null && <Tagger disabled={this.state.selectedNote != null} />}
                  {this.currentNote != null && <Tagger tags={this.currentNote.tags} disabled={this.state.selectedNote != null} />}

                  <Form.Item className="contentForm" style={{'flex':'0 1 auto', 'height':'100%'}} label={this.getContentsTitle()} name="contents" rules={[{
                      required: true,
                      message: 'Please enter the contents of your note.',
                      },]}>
                    <TextArea readOnly={this.state.selectedNote != null} className="addNoteContent" placeholder="Contents of note..." />
                  </Form.Item>

                   <Form.Item style={{'margin-bottom':'0px'}}>
                   <Button type="primary" htmlType="submit" disabled={this.state.selectedNote != null}>
                    Submit
                    </Button>

                    <Button onClick={this.resetWarning} disabled={this.state.selectedNote != null} danger>
                      Reset
                    </Button>
                  </Form.Item>

                </Form>
              </Card>
            </Col>
            <Col span={12} style={{'display':'flex'}}>
              <Card title={this.getOtherTitle()} className="compare-cards" bodyStyle={{height:'100%', display:'flex', 'flex-direction':'column'}}>
                {this.getOtherView()}
              </Card>
            </Col>
        </Row>
        </>
    )
  }
}


//export default StoreList;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPanel)); //FIXME: REVIEW!
