import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { setSearch } from '../actions/noteActions'
import { withRouter } from 'react-router-dom';

import { Input, Form, Affix } from 'antd';


import NoteComponent from '../functional/NoteComponent'

import {searchFilter} from '../../util/SearchUtils';

const { Search } = Input;


//FIXME: REVIEW!
const mapStateToProps = (state) => {
  // console.log('search state', state);
  return {
        notes: state.notes,
        lastSearch: state.lastSearch
        //searchStr: ""
      }
}


const mapDispatchToProps = (dispatch) => {

    return{
        setSearch: (search) => { dispatch(setSearch(search)) } //FIXME: DO BETTER, RENAME
    }
}

//Affix Reference: https://gary-shen.github.io/ant-design/components/affix/

class SearchPanel extends Component {

  //FIXME: ADD TAG SEARCHING
  //state = {searchStr: ""}
  formRef = React.createRef(); //Used to create a ref so that we can set the value of our search bar.

  tRef = React.createRef(); //FIXME: REM?

  noteClick = (note) => {
    // console.log(note);
    this.props.history.push(`/page/${note.uuid}`);
  }

  //FIXME: REVIEW
  onSearch = (searchTxt) => {

    this.props.setSearch(searchTxt);
    this.props = {
      ...this.props,
      lastSearch: searchTxt
    }
    this.formRef.current.setFieldsValue({searchbar : searchTxt});
  }




  render() {

    let searchStr = this.props.lastSearch;

    let notesList = searchFilter(this.props.notes, searchStr).map(note=>{
     return (
       <NoteComponent note={note} onTagClick={(e, tag) => {
         this.onSearch(tag);
         e.stopPropagation();
         this.forceUpdate(); //Re-render
       }}
       noteClicked={(note) => {
         this.noteClick(note);
       }} />
     )
    })
 //PLAN: MAKE ON CHANGE?
 //FIXME: MAKE SEARCH BAR ON TOP
    return (
      <div ref={(node) => { this.container = node; }} style={{'overflow-y':'auto',  'margin-right':'-16px', 'margin-left':'-16px'}}>

        <Affix target={() => this.container}>
          <div className="affixHeader">
            <Form ref={this.formRef}  initialValues={{'searchbar':searchStr}}>
            
              <Form.Item name="searchbar">
                <Search className="note-search-bar" placeholder="Search" allowClear onSearch={this.onSearch} />
              </Form.Item>
             </Form>
          </div>
        </Affix>
        <div>
          {notesList}
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchPanel)); //FIXME: REVIEW!
