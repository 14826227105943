import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { setSearch } from '../actions/noteActions'
import { withRouter } from 'react-router-dom';

import { Card, Tag } from 'antd';

import ReactMarkdown from 'react-markdown'



//FIXME: REVIEW!
const mapStateToProps = (state) => {
  console.log(state);
  return {
    notes: state.notes,
    lastSearch: state.lastSearch
    //searchStr: ""
  }
}


const mapDispatchToProps = (dispatch) => {

  return{
    setSearch: (search) => { dispatch(setSearch(search)) } //FIXME: DO BETTER, RENAME
  }
}


class NoteComponent extends Component {

  //FIXME: ADD TAG SEARCHING
  //state = {searchStr: ""}
  formRef = React.createRef(); //Used to create a ref so that we can set the value of our search bar.

  noteClick = (note) => {
    console.log(note);
    this.props.history.push(`/page/${note.uuid}`);
  }

  //FIXME: REVIEW
  onSearch = (searchTxt) => {

    this.props.setSearch(searchTxt);
    this.props = {
      ...this.props,
      lastSearch: searchTxt
    }
    this.formRef.current.setFieldsValue({searchbar : searchTxt});
  }

  //FIXME: MAKE TAG SEARCH WORK
  render() {



    let tagsList = this.props.note.tags.map(tag=>{
      return (
        <Tag key={tag} onClick={(e) => {
          //this.onSearch(tag); //FIXME: ENABLE
          //e.stopPropagation();
          //this.forceUpdate(); //Re-render
          this.props.onTagClick(e, tag);
        }}>
        {tag}
        </Tag>
      )
    })

/*
    return (
      <div style={{'display':'flex', 'background-color':'red', 'width':'85%', 'textAlign':'center', 'margin-left':'auto', 'margin-right':'auto'}}>
        <p> np </p>
        <div style={{'flex':'1 1 auto'}}>
        {tagsList}
        </div>
        </div>
    )
    */

    //FIXME: DEAL WITH MULTI LINE, B/C MD LOCK HEIGHT BETTER? (TWO ISSUES HERE)
    let displayText = (this.props.note.body.length > 100) ? this.props.note.body.slice(0, 100) + '...' : this.props.note.body;
    //FIXME:DO BETTER
    return (
      <Card key={this.props.note.uuid} className="full-note-card" title={this.props.note.title} onClick={() => {
        //this.noteClick(note)  //FIXME: ENABLE
        this.props.noteClicked(this.props.note);
        } }>
      <div style={{'margin-bottom':'16px'}}>{tagsList}</div>
      <ReactMarkdown>{displayText}</ReactMarkdown>
      </Card>
    )
    
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoteComponent)); //FIXME: REVIEW!
