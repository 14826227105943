import React from 'react';
import { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'

// import Navigator from './components/Navigator' //Unused
import ListPanel from './components/Panels/ListPanel' //List of everything in our datastore
import SearchPanel from './components/Panels/SearchPanel'
import AddPanel from './components/Panels/AddPanel'
import AboutPanel from './components/Panels/AboutPanel'

import PagePanel from './components/Panels/PagePanel'

/*
 * More TODOs:
 * Add Back button to page panel
 * Add similar notes to page panel
 * Add Edit to page panel
 * Make page panel show up diff in sidebar
 */
//FIXME: DO 404 PAGE
//FIXME: MAKE THINGS US UUID MORE
//import logo from './logo.svg';
import './App.less';

import AntNav from './components/AntNav'

import { Layout } from 'antd';
const { Content, Footer } = Layout;

class App extends Component {
  //TODO: MESS WITH CSS
  render(){
    return (
      <BrowserRouter>
        <div className="App">

        <Layout style={{ minHeight: '100vh' }}>
          <AntNav />
          <Layout className="site-layout">
            <Content style={{ padding: '16px', overflow:'hidden', height:'calc(100vh - 70px)', position:'relative', display:'flex', 'flex-direction':'column'}}>
                <Switch>
                  <Route exact path="/" render={() => <SearchPanel />} />
                  <Route exact path="/add" render={() => <AddPanel />} />
                  <Route exact path="/edit/:pageid" render={() => <AddPanel />} />
                  <Route exact path="/list" component={ListPanel} />
                  <Route exact path="/about" component={AboutPanel} />
                  <Route path="/page/:pageid" component={PagePanel} />
                  <Route component={AboutPanel} />
                </Switch>
            </Content>
            <Footer style={{ textAlign: 'center',  'zIndex':'0',  width:'100%', bottom:'0px'}}>XING</Footer>
          </Layout>

          </Layout>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
