import React from 'react';
import { Component } from 'react';
// import { connect } from 'react-redux';

import { Card } from 'antd';



class AboutPanel extends Component {


  render()
  {
    return (
      <Card title="Under Development—Please ignore. ">
      <p>Sample text</p>
      </Card>
    )
  }
}


export default AboutPanel;
