export const ADD_NOTE = 'ADD_NOTE'; //FIXME: DO BETTER?
export const PUT_NOTE = 'PUT_NOTE'; //FIXME: DO BETTER?
export const DEL_NOTE = 'DEL_NOTE';
export const SET_SEARCH = 'SET_SEARCH'; //FIXME: DO BETTER?



//FIXME: RENAME
export const addNote = (note) => {
  return {
    type: ADD_NOTE,
    note
  }
}

export const putNote = (note) => {
  return {
    type: PUT_NOTE,
    note
  }
}

export const delNote = (uuid) => {
  return {
    type: DEL_NOTE,
    uuid
  }
}


export const setSearch = (search) => {
  return {
    type: SET_SEARCH,
    search
  }
}
