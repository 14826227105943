import React from 'react';
// import { Component } from 'react';
import { Link } from 'react-router-dom';

import { withRouter } from 'react-router-dom';

import { Layout, Menu } from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  MenuOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  UserOutlined
} from '@ant-design/icons';


const { Sider } = Layout;



class AntNav extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  componentDidMount()
  {
    this.setState({url: window.location.pathname})
  }

  componentDidUpdate (prevProps, prevState) {
    if(prevState.url !== window.location.pathname)
      this.setState({url: window.location.pathname})
  }
  //https://ant.design/components/layout/
  render() {
    const { collapsed } = this.state;

    let initialKey = "-1"; //FIXME: DO BETTER w/ 404

    //This only occurs on first load
//FIXME: DO BETTER. THIS ALLOWS US TO SET HIGHLIGHTING OF MENU WHEN FIRST VISITING. CAN'T JUST USE URL AS KEY b/c IT USES CONTAINS, AND DUPLICATE ITEMS COULD BE SELECTED
    let url = this.state.url || window.location.pathname;
    // let cutIndex = url.indexOf('/', 1);

  //  if(cutIndex !== -1)
  //    url = url.substring(0, cutIndex);
    //console.log(url);
    switch (url){//window.location.pathname) {
      case "/":
        initialKey = "1";
        break;
      case "/add":
        initialKey = "2";
        break;
      case "/list":
        initialKey = "3";
        break;
      case "/settings":
        initialKey = "4";
        break;
      case "/user":
        initialKey = "5";
        break;
      case "/about":
        initialKey = "6";
        break;

      default:
        if(url.indexOf("/page") === 0) //FIXME: DOES NOT ALWAYS WORK
          initialKey = "3";
        else if(url.indexOf("/edit") === 0)
          initialKey = "2";

    }

    return (



        <Sider theme="light" collapsible collapsed={collapsed} onCollapse={this.onCollapse} style={{ 'zIndex':'1', height: '100vh', position: 'relative', left: 0}}>
          <Menu theme="light" selectedKeys={initialKey} mode="inline">
            <Menu.Item key="1" icon={<SearchOutlined />}>
              <Link to="/">Search </Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<PlusOutlined />}>
              <Link to="/add">Add</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<MenuOutlined />}>
              <Link to="/list">List</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<SettingOutlined />}>
              <Link to="/settings">Settings</Link>
            </Menu.Item>
          </Menu>
        </Sider>


    );
  }
}


export default withRouter(AntNav);
