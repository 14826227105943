import React from 'react';
import { Form, Input, Tag, Tooltip  } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import '../App.less';
//FIXME: ADD BTN DOESNT APPEAR CLICKABLE

//FIXME:  https://ant.design/components/tag/
class Tagger extends React.Component {

  state = {
    tags: [],
    inputVisible: false,
    inputValue: '',
    editInputIndex: -1,
    editInputValue: '',
  };

  constructor(props)
  {
    super(props);

    if(props.tags != null)
    {
      this.state = {
        ...this.state,
        tags: this.props.tags
      }

    }
  }

  closeTag = removedTag => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    this.setState({ tags });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = (add) => {
    const { inputValue } = this.state;
    let { tags } = this.state;

    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
      if(add != null)
        add(inputValue);
    }

    this.setState({
      tags,
      inputVisible: false,
      inputValue: '',
    });
  };

  saveInputRef = input => {
    this.input = input;
  };

  onFinish = values => {
    console.log('Received values of form:', values);
  };


  render() {
  const { tags, inputVisible, inputValue, editInputIndex, editInputValue } = this.state;
  return (
      <Form.List name="tags" rules={[
          {
            validator: async (_, tags) => {
              console.log('v and tags', tags);
              if (!tags || tags.length < 1) {
                return Promise.reject(new Error('Please add at least one tag.'));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
          <Form.Item>
            {fields.map(field => (
                <Tag disabled={this.props.disabled} key={field.key} closable={!this.props.disabled} onClose={() => {
                  this.closeTag(this.state.tags[field.name]);
                  remove(field.name)
                }}>
                {this.state.tags[field.name]}

                </Tag>

            ))}

              {(inputVisible && !this.props.disabled) && (
                <Input ref={this.saveInputRef} disabled={this.props.disabled}
                  type="text"
                  size="small"
                  className="tag-input"
                  value={inputValue}
                  onChange={this.handleInputChange}
                  onBlur={() => {
                    this.handleInputConfirm(add);
                  }}
                  onPressEnter={() => {
                    this.handleInputConfirm(add);
                  }}
                />
              )}
              {(!inputVisible && !this.props.disabled) && (
                <Tag className="site-tag-plus" onClick={() => {this.showInput()}}>
                  <PlusOutlined /> New Tag
                </Tag>
              )}

              <Form.ErrorList errors={errors}  />
            </Form.Item>
          </>
        )}
      </Form.List>

  )}
};

export default Tagger; //FIXME: REVIEW!
