import { ADD_NOTE, PUT_NOTE, DEL_NOTE, SET_SEARCH } from '../actions/noteActions'

//FIXME: DO BETTER, ESPECIALLY WITH UUIDS
const initState = {
  notes:[
    {
      "title":"Note One",
      "tags":["tag1", "tag3"],
      "body":"This is the first sample note!",
      "uuid":'0'
    },
    {
      "title":"Note Two",
      "tags":["tag2"],
      "body":"This is the second sample note!",
      "uuid":'1'
    },
  ],
  lastSearch: ""
}

const noteReducer = (state = initState, action)=>{ //FIXME: STUDY

    if(action.type === ADD_NOTE) //FIXME: CASE SWITCH
    {
      return {
        ...state,
        notes:  [...state.notes, action.note]
      }
      //state.notes = [...state.notes, action.note] //FIXME: CHECK
    }
    else if(action.type === PUT_NOTE)
    {
      //FIXME: RESTRUCTURE DATA AS HASHSET W/ UUID
      for(let i in state.notes)
      {
        if(state.notes[i].uuid === action.note.uuid)
        {
          state.notes[i] = action.note;
          break; //Make sure we don't somehow duplicate
        }
      }
    }
    else if(action.type === DEL_NOTE)
    {
      return {
        ...state,
        notes: state.notes.filter(note => note.uuid !== action.uuid) //FIXME: DO BETTER b/c UUID SHOULD BE UNIQUE
      }
    }
    else if(action.type === SET_SEARCH)
    {
      return {
        ...state,
        lastSearch: action.search
      }
    }
    console.log(state)
    return state;
}

export default noteReducer;
