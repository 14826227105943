import React from 'react';
import { Component } from 'react';

import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

//FIXME: BETTER NAME
class InfoTooltip extends Component {


  render() {

    return (
      <Tooltip title={this.props.text} onClick={(e) => {
        if(this.props.onClick != null)
        this.props.onClick(e)
      }}>
        <QuestionCircleOutlined />
      </Tooltip>
    )
  }
}


export default InfoTooltip;
